<template>
  <div :id="`question-${index}`" class="d-flex align-items-center mb-10">
    <div class="c-item flex-grow-1">
      <div class="item-header">Section {{ item.number_section }}</div>

      <div style="background: #1ae892; border-radius: 0px 0px 14px 14px">
        <div class="item-body">
          <div class="d-flex">
            <b-form-input
              v-model="item.name_section"
              placeholder="Nama Section"
              class="h-auto mr-4 py-2"
            ></b-form-input>

            <!-- <vue-select
              v-model="model"
              :options="questionType"
              placeholder="Pilih Jenis"
              label="name"
              :style="{'min-width': '200px' }"
              class="c-select-input-2"
            >
            </vue-select> -->

            <b-dropdown
              size="sm"
              variant="link"
              menu-class="px-0 text-primary"
              toggle-class="py-0 px-0"
              style="width: 250px"
              left
              no-flip
            >
              <template v-slot:button-content>
                <b-form-input
                  class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                  :value="questionType[item.type_question].name"
                  disabled
                ></b-form-input>
              </template>

              <template v-for="_item in questionType">
                <b-dropdown-item
                  v-if="_item.id != 4"
                  :key="_item.id"
                  @click="
                    item.type_question = _item.id;
                    item = item;
                  "
                >
                  <div>
                    <img
                      v-if="_item.icon"
                      :src="_item.icon"
                      height="10px"
                      class="mr-2"
                    />
                    {{ _item.name }}
                  </div>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </div>

          <div class="d-flex justify-content-end">
            <div @click="onDelete()" class="c-delete-btn" v-ripple>
              <img
                :src="`${baseAppPath}/media/delete-2-icon.svg`"
                width="16px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        @click="index > 0 && moveUp()"
        :style="{ opacity: index > 0 ? 1 : 0 }"
        class="c-move-up-btn"
        v-ripple
      >
        <img :src="`${baseAppPath}/media/chevron-left-icon.svg`" width="16px" />
      </div>
      <div
        @click="canMoveDown && moveDown()"
        :style="{ opacity: canMoveDown ? 1 : 0 }"
        class="c-move-down-btn"
        v-ripple
      >
        <img :src="`${baseAppPath}/media/chevron-left-icon.svg`" width="16px" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.c-select-input-2 {
  border-radius: 10px !important;
  background-color: #ffffff !important;

  #questioner-builder {
    input {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      background-color: #ffffff !important;
      border: inherit !important;
      border-radius: inherit !important;
    }
  }
  ul {
    padding-left: 0px !important;
  }
  li {
    padding-left: 10px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}
</style>

<style lang="scss" scoped>
.c-item {
  border-radius: 14px;
  background: #236240;

  .item-header {
    padding: 12px 25px;
    border-radius: 40px 40px 100px 0px;
    background: #1ae892;
  }
  .item-body {
    padding: 16px 25px;
    border-radius: 40px 0px 14px 14px;
    background: #236240;
  }
}

.c-delete-btn {
  margin-top: 12px;
  padding: 10px 13px;
  border-radius: 10px;
  background-color: rgba(51, 51, 51, 0.42);
  cursor: pointer;
}

.c-move-up-btn,
.c-move-down-btn {
  margin: 5px 6px;
  padding: 8px 13px;
  border-radius: 10px;
  cursor: pointer;

  img {
    width: 8px;
    height: 14px;
  }
}

.c-move-up-btn img {
  transform: rotate(90deg);
}
.c-move-down-btn img {
  transform: rotate(-90deg);
}
</style>

<script>
import Moment from "moment";

export default {
  props: [
    "id",
    "item",
    "index",
    "canMoveDown",
    "onDelete",
    "moveUp",
    "moveDown"
  ],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY HH:mm");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    }
  }
};
</script>
