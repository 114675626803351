<template>
  <div :id="`question-${index}`" class="d-flex align-items-center mb-10">
    <div class="c-item flex-grow-1">
      <div class="item-header">Pertanyaan {{ item.number_question }}</div>

      <div style="border-radius: 0px 0px 14px 14px">
        <div class="item-body">
          <div class="d-flex">
            <b-form-input
              v-model="item.question"
              placeholder="Tulis Pertanyaan"
              class="h-auto py-2"
            ></b-form-input>

            <div
              class="position-relative overflow-hidden d-flex align-items-center justify-content-center px-3 mx-2 rounded"
              style="cursor: pointer"
              v-ripple
            >
              <img :src="`${baseAppPath}/media/photo-icon.svg`" width="26px" />

              <b-form-file
                placeholder="Pilih Gambar"
                class="c-file-input-2 form-control form-control-solid h-auto pl-3 pr-7 pb-0"
                v-model="item.attached_img"
              ></b-form-file>
            </div>

            <b-dropdown
              size="sm"
              variant="link"
              menu-class="px-0 text-primary"
              toggle-class="py-0 px-0"
              style="width: 250px"
              left
              no-flip
            >
              <template v-slot:button-content>
                <b-form-input
                  class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                  :value="questionType[item.type_question].name"
                  disabled
                ></b-form-input>
              </template>

              <template v-for="_item in questionType">
                <b-dropdown-item
                  v-if="_item.id != 4"
                  :key="_item.id"
                  @click="
                    item.type_question = _item.id;
                    item.arrAnswer = [];
                  "
                >
                  <div>
                    <img
                      v-if="_item.icon"
                      :src="_item.icon"
                      height="10px"
                      class="mr-2"
                    />
                    {{ _item.name }}
                  </div>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </div>

          <div
            v-if="item.attached_img"
            class="q-image"
            :style="{
              'background-image':
                typeof item.attached_img == 'object'
                  ? `url(${localFile(item.attached_img)})`
                  : `url('${item.attached_img}')`
            }"
          >
            <button
              @click="item.attached_img = null"
              class="position-absolute btn btn-danger d-block font-weight-bolder px-4 py-1 mt-2"
              style="border-radius: 100px; top: 10px; right: 10px"
              v-ripple
            >
              Hapus Gambar
            </button>
          </div>

          <div class="d-flex justify-content-end align-items-center">
            <div class="mt-4">Wajib diisi</div>
            <div class="custom-control custom-switch custom-switch-2">
              <input
                v-model="item.is_mandatory"
                size="lg"
                type="checkbox"
                class="custom-control-input"
                :id="'customSwitches_' + index"
              />
              <label
                class="custom-control-label"
                :for="'customSwitches_' + index"
              ></label>
            </div>
            <div @click="onDuplicate()" class="c-delete-btn" v-ripple>
              <img :src="`${baseAppPath}/media/copy-icon.svg`" width="16px" />
            </div>
            <div @click="onDelete()" class="c-delete-btn" v-ripple>
              <img
                :src="`${baseAppPath}/media/delete-2-icon.svg`"
                width="16px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        @click="index > 0 && moveUp()"
        :style="{ opacity: index > 0 ? 1 : 0 }"
        class="c-move-up-btn"
        v-ripple
      >
        <img :src="`${baseAppPath}/media/chevron-left-icon.svg`" width="16px" />
      </div>
      <div
        @click="canMoveDown && moveDown()"
        :style="{ opacity: canMoveDown ? 1 : 0 }"
        class="c-move-down-btn"
        v-ripple
      >
        <img :src="`${baseAppPath}/media/chevron-left-icon.svg`" width="16px" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.c-select-input-2 {
  border-radius: 10px !important;
  background-color: #ffffff !important;

  #questioner-builder {
    input {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      background-color: #ffffff !important;
      border: inherit !important;
      border-radius: inherit !important;
    }
  }
  ul {
    padding-left: 0px !important;
  }
  li {
    padding-left: 10px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}

.custom-switch-2 {
  margin-right: 24px;
  margin-top: 11px;
  display: inline-block;
  transform: scale(1.5) translateX(10px);
}
</style>

<style lang="scss" scoped>
.c-item {
  border: 1px solid #d7d4d3;
  border-radius: 14px;

  .item-header {
    padding: 15px 25px;
    border-radius: 40px 40px 100px 0px;
  }
  .item-body {
    padding: 0px 25px 16px 25px;
    border-radius: 40px 0px 14px 14px;
  }
}

.c-delete-btn {
  margin-top: 12px;
  padding: 10px 13px;
  border-radius: 10px;
  cursor: pointer;
}

.c-move-up-btn,
.c-move-down-btn {
  margin: 5px 6px;
  padding: 8px 13px;
  border-radius: 10px;
  cursor: pointer;

  img {
    width: 8px;
    height: 14px;
  }
}

.c-move-up-btn img {
  transform: rotate(90deg);
}
.c-move-down-btn img {
  transform: rotate(-90deg);
}
</style>

<script>
import Moment from "moment";

export default {
  props: [
    "id",
    "item",
    "index",
    "canMoveDown",
    "onDelete",
    "onDuplicate",
    "moveUp",
    "moveDown"
  ],
  components: {},
  data() {
    return {};
  },
  beforeMount() {
    this.item.arrAnswer = [];
  },
  methods: {
    localFile(file) {
      return URL.createObjectURL(file);
    },

    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY HH:mm");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    }
  }
};
</script>
