<template>
  <div id="questioner-builder">
    <div
      v-if="loading"
      class="spinner align-self-center mx-auto mt-20"
      style="width: 50px"
      v-bind:class="'spinner-primary'"
    ></div>

    <div v-if="!loading">
      <div class="breadcrumb-text">
        Survey / Daftar Data Survey / {{ data.name_survey }} / Edit Survey
      </div>

      <div class="tab-container">
        <div v-ripple class="tab-item" v-on:click="$router.back()">
          &#60; Back
        </div>
      </div>

      <div class="q-action">
        <div class="q-action-btn" v-ripple @click="recordDialog = true">
          <img :src="`${baseAppPath}/media/record-icon.svg`" width="22px" />
          <div class="q-action-btn-text">Rec</div>
        </div>

        <div class="divider"></div>

        <div class="q-action-btn" v-ripple @click="onSubmit">
          <img :src="`${baseAppPath}/media/save-green-icon.svg`" width="22px" />
          <div class="q-action-btn-text">Simpan</div>
        </div>

        <div class="divider"></div>

        <div class="q-action-btn" v-ripple @click="reset">
          <img
            :src="`${baseAppPath}/media/reset-green-icon.svg`"
            width="22px"
          />
          <div class="q-action-btn-text">Reset</div>
        </div>
      </div>

      <div class="table-container">
        <div class="title">{{ data.name_survey }}</div>
        <div v-if="data.created_at" class="time mb-8">
          Created At: {{ formatDate(data.created_at) }}
        </div>

        <b-form class="form" @submit.stop.prevent>
          <template
            v-for="(item, index) in data.arrQuestion.filter(
              el => el.id_question >= 0 || el.id >= 0
            )"
          >
            <Section
              v-if="item.type_question == 0"
              :key="'q-' + index + '-' + item.id"
              :item="item"
              :index="index"
              :onDelete="() => deleteQuestion(index)"
              :moveUp="() => moveUp(index)"
              :moveDown="() => moveDown(index)"
              :canMoveDown="
                data.arrQuestion.filter(el => el.id_question >= 0 || el.id >= 0)
                  .length !=
                  index + 1
              "
            />

            <Essay
              v-if="item.type_question == 1"
              :key="'q-' + index + '-' + item.id_question"
              :item="item"
              :index="index"
              :onDelete="() => deleteQuestion(index)"
              :onDuplicate="() => duplicateQueston(item, index)"
              :moveUp="() => moveUp(index)"
              :moveDown="() => moveDown(index)"
              :canMoveDown="
                data.arrQuestion.filter(el => el.id_question >= 0 || el.id >= 0)
                  .length !=
                  index + 1
              "
            />

            <MultipleChoice
              v-if="item.type_question == 2"
              :key="'q-' + index + '-' + item.id_question"
              :item="item"
              :index="index"
              :onDelete="() => deleteQuestion(index)"
              :onDuplicate="() => duplicateQueston(item, index)"
              :moveUp="() => moveUp(index)"
              :moveDown="() => moveDown(index)"
              :canMoveDown="
                data.arrQuestion.filter(el => el.id_question >= 0 || el.id >= 0)
                  .length !=
                  index + 1
              "
            />

            <Checkbox
              v-if="item.type_question == 3"
              :key="'q-' + index + '-' + item.id_question"
              :item="item"
              :index="index"
              :onDelete="() => deleteQuestion(index)"
              :onDuplicate="() => duplicateQueston(item, index)"
              :moveUp="() => moveUp(index)"
              :moveDown="() => moveDown(index)"
              :canMoveDown="
                data.arrQuestion.filter(el => el.id_question >= 0 || el.id >= 0)
                  .length !=
                  index + 1
              "
            />

            <ImageQuestion
              v-if="item.type_question == 5"
              :key="'q-' + index + '-' + item.id_question"
              :item="item"
              :index="index"
              :onDelete="() => deleteQuestion(index)"
              :onDuplicate="() => duplicateQueston(item, index)"
              :moveUp="() => moveUp(index)"
              :moveDown="() => moveDown(index)"
              :canMoveDown="
                data.arrQuestion.filter(el => el.id_question >= 0 || el.id >= 0)
                  .length !=
                  index + 1
              "
            />

            <Matrix
              v-if="[6, 7, 8].includes(item.type_question)"
              :key="'q-' + index + '-' + item.id_question"
              :item="item"
              :index="index"
              :onDelete="() => deleteQuestion(index)"
              :onDuplicate="() => duplicateQueston(item, index)"
              :moveUp="() => moveUp(index)"
              :moveDown="() => moveDown(index)"
              :canMoveDown="
                data.arrQuestion.filter(el => el.id_question >= 0 || el.id >= 0)
                  .length !=
                  index + 1
              "
            />

            <File
              v-if="item.type_question == 9"
              :key="'q-' + index + '-' + item.id_question"
              :item="item"
              :index="index"
              :onDelete="() => deleteQuestion(index)"
              :onDuplicate="() => duplicateQueston(item, index)"
              :moveUp="() => moveUp(index)"
              :moveDown="() => moveDown(index)"
              :canMoveDown="
                data.arrQuestion.filter(el => el.id_question >= 0 || el.id >= 0)
                  .length !=
                  index + 1
              "
            />
          </template>
        </b-form>

        <div class="d-flex justify-content-end" style="padding-right: 48px">
          <button
            @click="addQuestion"
            class="btn btn-secondary d-block font-weight-bolder px-8 py-4 mt-5 ml-11"
            style="border-radius: 10px; font-size: 16px"
            v-ripple
          >
            + Tambah Pertanyaan
          </button>
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: recordDialog ? 'inherit' : '0px' }"
    >
      <v-dialog v-model="recordDialog" width="500px" persistent>
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div class="dialog-title text-primary">
                Izinkan untuk melakukan rekaman?
              </div>

              <div class="d-flex justify-content-center mt-6 mb-6">
                <div
                  :key="`tolak-${data.recording.id_question}`"
                  class="tolak-btn"
                  :style="
                    data.recording.id_question == null ||
                    data.recording.id_question < 0
                      ? 'background: #E32910; color: #FFFFFF'
                      : 'background: #FFFFFF; color: #E32910'
                  "
                  @click="() => toggleRecording(false)"
                  v-ripple
                >
                  Tolak
                </div>
                <div
                  :key="`izinkan-${data.recording.id_question}`"
                  class="izinkan-btn"
                  :style="
                    data.recording.id_question == 0 ||
                    data.recording.id_question > 0
                      ? 'background: #06B56F; color: #FFFFFF'
                      : 'background: #FFFFFF; color: #06B56F'
                  "
                  @click="() => toggleRecording(true)"
                  v-ripple
                >
                  Izinkan
                </div>
              </div>

              <div
                v-if="
                  data.recording.id_question == 0 ||
                    data.recording.id_question > 0
                "
              >
                <b-form-group>
                  <div class="input-title">
                    Mulai rekaman pada pertaanyan ke
                  </div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3"
                    type="number"
                    autocomplete="off"
                    v-model="data.recording.start"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">
                    Akhiri rekaman pada pertanyaan ke
                  </div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3"
                    type="number"
                    autocomplete="off"
                    v-model="data.recording.end"
                  ></b-form-input>
                </b-form-group>
              </div>

              <button
                @click="recordingSubmit"
                class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-2 mb-3 mt-10 font-size-3"
                style="border-radius: 100px"
                v-ripple
              >
                Selesai
              </button>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss">
.form-group {
  margin-bottom: 10px;
}
.input-title {
  margin-bottom: 6px;
  color: #8c8c8c;
  font-size: 12px;
}

#questioner-builder {
  input {
    font-size: 14px !important;
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-radius: 8px !important;
  }
}

.c-file-input-2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;

  .custom-file-input {
    height: inherit !important;
  }
  .custom-file-label {
    height: 40px;
    margin-bottom: 0px !important;
    border: 1px solid #d7d4d3 !important;
    border-radius: 8px !important;
  }
}

.btn-group {
  width: 100%;

  .dropdown-toggle.nav-link:after,
  .dropdown-toggle.btn:after {
    position: absolute !important;
    right: 15px !important;
    top: 41% !important;
  }

  .dropdown-item {
    padding: 0.45rem 1.25rem !important;
  }
}

.q-image {
  position: relative;
  margin: 10px 0px;
  height: 250px;
  border-radius: 8px;
  border: 1px solid #d7d4d3;
  background-size: contain;
  background-position: center center;
}
</style>

<style lang="scss" scoped>
.divider {
  height: 1px;
  background: rgba(31, 31, 31, 0.1);
}

.q-action {
  position: absolute;
  top: 40%;
  right: 0;
  border-radius: 10px 0px 0px 10px;
  background: #ffffff;
  z-index: 99;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(6, 181, 111, 0.63);
  -moz-box-shadow: 0px 0px 16px 0px rgba(6, 181, 111, 0.63);
  box-shadow: 0px 0px 16px 0px rgba(6, 181, 111, 0.63);

  .q-action-btn:nth-child(1):hover {
    background: #e32910;

    img {
      filter: brightness(1000%);
    }
    .q-action-btn-text {
      color: #ffffff;
    }
  }
  .q-action-btn:nth-child(3):hover {
    background: #06b56f;

    img {
      filter: brightness(1000%);
    }
    .q-action-btn-text {
      color: #ffffff;
    }
  }
  .q-action-btn:nth-child(5):hover {
    background: #223dac;

    img {
      filter: brightness(1000%);
    }
    .q-action-btn-text {
      color: #ffffff;
    }
  }

  .q-action-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
  }
  .q-action-btn-text {
    margin-top: 2px;
    font-size: 10px;
    text-align: center;
    color: #06b56f;
  }
}

.tab-container {
  display: flex;
  margin-bottom: 15px;

  .tab-item {
    padding: 5px 28px;
    margin-right: 10px;
    color: #06b56f;
    font-size: 12px;
    border: 1px solid #06b56f;
    border-radius: 8px;
    cursor: pointer;
    transition: all 400ms;
    -webkit-box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);
    -moz-box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);
    box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);

    &:hover {
      -webkit-box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
      -moz-box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
      box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
    }
  }
  .tab-item-active {
    color: #ffffff;
    font-weight: bold;
    background: #06b56f;
    -webkit-box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63);
    -moz-box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63);
    box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63);
  }
}

.title-wrapper {
  margin-bottom: 20px;
  padding: 0px 20px;
  border-left: 4px solid #0fe17a;
}
.title {
  margin-bottom: 6px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
.time {
  margin-bottom: 6px;
  font-size: 14px;
  font-style: normal;
  color: #8c8c8c;
}
.desc {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
}
.modal-desc {
  font-size: 14px;
  font-style: normal;
}

.table-container {
  position: relative;
  min-height: 80vh;
  padding: 20px;
  margin-right: 50px;
  border-radius: 16px;
  background-color: #ffffff;
  -webkit-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  -moz-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
}

.q-item {
  margin-top: 25px;
  padding: 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .q-item-index {
    margin-bottom: 8px;
  }

  .q-answer-wrapper {
    .q-radio {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      margin-right: 10px;
      width: 26px;
      height: 26px;
      border-radius: 30px;
      border: 2px solid #06b56f;
      cursor: pointer;

      .q-radio-active {
        width: 16px;
        height: 16px;
        border-radius: 30px;
        background-color: #06b56f;
      }
    }
  }

  .q-answer-delete {
    margin-left: 10px;
    padding: 9px;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.c-dialog {
  position: relative;
  max-height: 80vh;

  .dialog-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #8c8c8c;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .input-title {
    margin-bottom: 6px;
    color: #8c8c8c;
    font-size: 12px;
  }
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-radius: 8px !important;
  }
  .c-select-input {
    input {
      padding-top: 4px;
      padding-bottom: 4px;
      background-color: inherit !important;
      border: inherit !important;
      border-radius: inherit !important;
    }
    ul {
      padding-left: 0px;
    }
    li {
      padding-left: 10px;
    }
  }
}
.dialog-close {
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 14px;
  border-radius: 100px;
  background-color: #ffffff;
  cursor: pointer;
}

.tolak-btn {
  padding: 6px 20px;
  width: 100px;
  text-align: center;
  border: 1px solid #e32910;
  border-right: 0px;
  border-radius: 6px 0px 0px 6px;
  cursor: pointer;
}
.izinkan-btn {
  padding: 6px 20px;
  width: 100px;
  text-align: center;
  border: 1px solid #06b56f;
  border-left: 0px;
  border-radius: 0px 6px 6px 0px;
  cursor: pointer;
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}
</style>

<script>
import Moment from "moment";
import { BToast } from "bootstrap-vue";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import Section from "./Section.vue";
import Essay from "./Essay.vue";
import MultipleChoice from "./MultipleChoice.vue";
import Checkbox from "./Checkbox.vue";
import ImageQuestion from "./Image.vue";
import Matrix from "./Matrix.vue";
import File from "./File.vue";

const recording = {
  id_question: null,
  type_question: 4,
  id_answer: null,
  start: null,
  end: null
};

export default {
  name: "survey_online_question",
  props: ["id", "id_user"],
  components: {
    Section,
    Essay,
    MultipleChoice,
    Checkbox,
    ImageQuestion,
    Matrix,
    File
  },
  data() {
    return {
      baseUrl: ApiService.baseUrl,
      activeTabIndex: 0,

      data: {
        arrQuestion: [],
        section: [],
        recording
      },
      mode: "create", // create, edit
      arrQuestion: [],

      dialog: false,
      recordDialog: false,

      loading: true
    };
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    async getData() {
      this.loading = true;
      this.data = {
        arrQuestion: [],
        section: [],
        recording: recording
      };

      await ApiService.get(
        `api/question/get_all?id_survey=${this.id}&id_user=${
          this.id_user
        }&token=${JwtService.getToken("survey")}`,
        "survey"
      )
        .then(({ data }) => {
          if (data?.response) {
            let _section = data?.response?.section;
            _section.forEach(item => {
              item.type_question = 0;
            });

            this.data = data?.response?.recording
              ? data?.response
              : { ...data?.response, recording };
            this.mode = "edit";

            this.data.arrQuestion = [
              ..._section,
              ...data?.response?.arrQuestion
            ].sort(function(a, b) {
              if (a.number_section > b.number_section) {
                return 1;
              }
              if (a.number_section < b.number_section) {
                return -1;
              }
              return 0;
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      var _this = this;
      this.$swal({
        title: "Batal ubah data?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          _this.getData();
        }
      });
    },

    reorderNumber() {
      this.data.arrQuestion
        .filter(el => !el?.id)
        .forEach((item, index) => {
          item.number_question = index + 1;
        });
      this.data.arrQuestion
        .filter(el => el?.id)
        .forEach((item, index) => {
          item.number_section = index + 1;
        });
    },
    moveUp(index) {
      this.data.arrQuestion.splice(
        index - 1,
        0,
        this.data.arrQuestion.splice(index, 1)[0]
      );
      this.reorderNumber();

      setTimeout(() => {
        let myEl = document.getElementById(`question-${index - 1}`);
        this.$smoothScroll({
          scrollTo: myEl,
          duration: 400,
          container: document.getElementById("content-wrapper"),
          offset: -100
        });
      }, 100);
    },
    moveDown(index) {
      this.data.arrQuestion.splice(
        index + 1,
        0,
        this.data.arrQuestion.splice(index, 1)[0]
      );
      this.reorderNumber();

      setTimeout(() => {
        let myEl = document.getElementById(`question-${index + 1}`);
        this.$smoothScroll({
          scrollTo: myEl,
          duration: 400,
          container: document.getElementById("content-wrapper"),
          offset: -100
        });
      }, 100);
    },

    addQuestion() {
      this.data.arrQuestion = [
        ...this.data.arrQuestion,
        {
          id_section: 0,
          name_section: null,
          number_section: null,
          id_question: 0,
          number_question: null,
          type_question: 1,
          question: null,
          attached_img: null,
          is_mandatory: false,
          published: null,
          arrAnswer: []
        }
      ];

      this.reorderNumber();
    },

    deleteQuestion(index) {
      let _arrQuestion = this.data.arrQuestion.filter(
        el => el?.id_question >= 0 || el?.id >= 0
      );
      if (
        _arrQuestion[index]?.id_question == 0 ||
        _arrQuestion[index]?.id == 0
      ) {
        _arrQuestion.splice(index, 1);
      } else {
        if (_arrQuestion[index]?.id) {
          _arrQuestion[index].id = -_arrQuestion[index].id;
        } else {
          _arrQuestion[index].id_question = -_arrQuestion[index].id_question;
        }
      }

      this.data.arrQuestion = _arrQuestion;
    },

    duplicateQueston(item, index) {
      // let _arrQuestion = this.data.arrQuestion.filter(el => el.id_question >= 0 || el.id >= 0)
      this.data.arrQuestion.splice(index + 1, 0, {
        ...item,
        id_question: 0,
        number_question: index + 1
      });
      this.reorderNumber();

      setTimeout(() => {
        let myEl = document.getElementById(`question-${index + 1}`);
        this.$smoothScroll({
          scrollTo: myEl,
          duration: 400,
          container: document.getElementById("content-wrapper"),
          offset: -100
        });
      }, 200);
    },

    toggleRecording(active) {
      let recQuestionId = this.data?.recording?.id_question;
      if (active) {
        this.data.recording.id_question =
          recQuestionId > 0 ? Math.abs(this.data.recording.id_question) : 0;
      } else {
        this.data.recording.id_question =
          recQuestionId > 0 ? -this.data.recording.id_question : null;
      }

      let recAnswerId = this.data?.recording?.id_answer;
      if (active) {
        this.data.recording.id_answer =
          recAnswerId > 0 ? Math.abs(this.data.recording.id_answer) : 0;
      } else {
        this.data.recording.id_answer =
          recAnswerId > 0 ? -this.data.recording.id_answer : null;
      }

      this.data = this.data;
    },

    recordingSubmit() {
      if (
        this.data.recording.id_question == 0 ||
        this.data.recording.id_question > 0
      ) {
        if (!this.data.recording.start || !this.data.recording.end) {
          let bootStrapToaster = new BToast();
          bootStrapToaster.$bvToast.toast("Waktu mulai dan akhir harus diisi", {
            title: "Error",
            toaster: "b-toaster-top-right",
            variant: "danger",
            solid: true,
            appendToast: true
          });
        } else {
          this.recordDialog = false;
        }
      } else {
        this.recordDialog = false;
      }
    },

    onSubmit() {
      var _this = this;

      let formData = new FormData();

      formData.append("id_survey", this.id);
      formData.append("id_user", this.id_user);
      formData.append("token", JwtService.getToken("survey"));
      formData.append("_method", "PATCH");

      let questions = this.data.arrQuestion;
      let currentSection;
      let questionNumber = 0;

      for (let i = 0; i < questions.length; i++) {
        const qIndex = questions[i].number_question - 1;

        if (questions[i].number_question >= 0) {
          questionNumber++;
        }

        if (questions[i].id) {
          currentSection = questions[i];
        }

        if (!questions[i].id) {
          formData.append(
            `arrQuestion[${qIndex}][number_question]`,
            questions[i].number_question
          );
          formData.append(
            `arrQuestion[${qIndex}][id_question]`,
            questions[i].id_question
          );
          formData.append(
            `arrQuestion[${qIndex}][question]`,
            questions[i].question
          );
          formData.append(
            `arrQuestion[${qIndex}][type]`,
            questions[i].type_question
          );
          formData.append(
            `arrQuestion[${qIndex}][is_mandatory]`,
            questions[i].is_mandatory ? "Yes" : "No"
          );

          if (currentSection) {
            formData.append(
              `arrQuestion[${qIndex}][id_section]`,
              currentSection.id
            );
            formData.append(
              `arrQuestion[${qIndex}][number_section]`,
              currentSection.number_section
            );
            formData.append(
              `arrQuestion[${qIndex}][name_section]`,
              currentSection.name_section
            );
          }

          if (typeof questions[i].attached_img !== "string") {
            formData.append(
              `arrQuestion[${qIndex}][attached_img]`,
              questions[i].attached_img
            );
          }

          if (![1, 5, 9].includes(questions[i].type_question)) {
            for (let j = 0; j < questions[i].arrAnswer.length; j++) {
              formData.append(
                `arrQuestion[${qIndex}][arrAnswer][${j}][id_answer]`,
                questions[i].arrAnswer[j].id_answer
              );
              formData.append(
                `arrQuestion[${qIndex}][arrAnswer][${j}][answer_other]`,
                questions[i].arrAnswer[j].answer_other
              );

              if (typeof questions[i].arrAnswer[j].answer == "object") {
                for (
                  let k = 0;
                  k < questions[i].arrAnswer[j].answer.length;
                  k++
                ) {
                  formData.append(
                    `arrQuestion[${qIndex}][arrAnswer][${j}][answer][${k}]`,
                    questions[i].arrAnswer[j].answer[k]
                  );
                }
              } else {
                formData.append(
                  `arrQuestion[${qIndex}][arrAnswer][${j}][answer]`,
                  questions[i].arrAnswer[j].answer_other == 1
                    ? "other"
                    : questions[i].arrAnswer[j].answer
                );
              }

              if (questions[i].arrAnswer[j].jump_to_question) {
                formData.append(
                  `arrQuestion[${qIndex}][arrAnswer][${j}][jump_to_question]`,
                  questions[i].arrAnswer[j].jump_to_question
                );
              }
            }
          }
        }

        if (i + 1 == questions.length) {
          if (this.data?.recording?.id_question != null) {
            formData.append(
              `arrQuestion[${questionNumber}][number_question]`,
              0
            );
            formData.append(`arrQuestion[${questionNumber}][type]`, 4);
            formData.append(
              `arrQuestion[${questionNumber}][question]`,
              "recording"
            );
            formData.append(
              `arrQuestion[${questionNumber}][id_question]`,
              this.data?.recording?.id_question
            );
            formData.append(
              `arrQuestion[${questionNumber}][id_answer]`,
              this.data?.recording?.id_answer
            );
            formData.append(
              `arrQuestion[${questionNumber}][is_mandatory]`,
              "Yes"
            );
            formData.append(
              `arrQuestion[${questionNumber}][arrAnswer][0][answer][0]`,
              this.data?.recording?.start
            );
            formData.append(
              `arrQuestion[${questionNumber}][arrAnswer][0][answer][1]`,
              this.data?.recording?.end
            );
            formData.append(
              `arrQuestion[${questionNumber}][arrAnswer][0][id_answer]`,
              this.data?.recording?.id_answer
            );
          }
        }
      }

      let sections = this.data.arrQuestion.filter(el => el.id || el.id >= 0);

      for (let i = 0; i < sections.length; i++) {
        formData.append(`arrSection[${i}][id_section]`, sections[i].id);
        formData.append(
          `arrSection[${i}][number_section]`,
          sections[i].number_section
        );
        formData.append(
          `arrSection[${i}][name_section]`,
          sections[i].name_section
        );
      }

      this.$swal({
        title: "Simpan Data?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.post(`api/question/${_this.mode}`, formData, "survey")
                .then(({ data }) => {
                  if (data?.response?.code == 200) {
                    let bootStrapToaster = new BToast();
                    bootStrapToaster.$bvToast.toast(
                      "Kuisioner berhasil disimpan",
                      {
                        title: "Success",
                        toaster: "b-toaster-top-right",
                        variant: "success",
                        solid: true,
                        appendToast: true
                      }
                    );
                    resolve();
                  } else {
                    reject();
                  }
                })
                .catch(() => {
                  reject();
                })
                .finally(() => {
                  _this.loading = false;
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },

    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY HH:mm");
    }
  }
};
</script>
