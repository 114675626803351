var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"questioner-builder"}},[(_vm.loading)?_c('div',{staticClass:"spinner align-self-center mx-auto mt-20",class:'spinner-primary',staticStyle:{"width":"50px"}}):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"breadcrumb-text"},[_vm._v(" Survey / Daftar Data Survey / "+_vm._s(_vm.data.name_survey)+" / Edit Survey ")]),_c('div',{staticClass:"tab-container"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"tab-item",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" < Back ")])]),_c('div',{staticClass:"q-action"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"q-action-btn",on:{"click":function($event){_vm.recordDialog = true}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/record-icon.svg"),"width":"22px"}}),_c('div',{staticClass:"q-action-btn-text"},[_vm._v("Rec")])]),_c('div',{staticClass:"divider"}),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"q-action-btn",on:{"click":_vm.onSubmit}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/save-green-icon.svg"),"width":"22px"}}),_c('div',{staticClass:"q-action-btn-text"},[_vm._v("Simpan")])]),_c('div',{staticClass:"divider"}),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"q-action-btn",on:{"click":_vm.reset}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/reset-green-icon.svg"),"width":"22px"}}),_c('div',{staticClass:"q-action-btn-text"},[_vm._v("Reset")])])]),_c('div',{staticClass:"table-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.data.name_survey))]),(_vm.data.created_at)?_c('div',{staticClass:"time mb-8"},[_vm._v(" Created At: "+_vm._s(_vm.formatDate(_vm.data.created_at))+" ")]):_vm._e(),_c('b-form',{staticClass:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._l((_vm.data.arrQuestion.filter(
            function (el) { return el.id_question >= 0 || el.id >= 0; }
          )),function(item,index){return [(item.type_question == 0)?_c('Section',{key:'q-' + index + '-' + item.id,attrs:{"item":item,"index":index,"onDelete":function () { return _vm.deleteQuestion(index); },"moveUp":function () { return _vm.moveUp(index); },"moveDown":function () { return _vm.moveDown(index); },"canMoveDown":_vm.data.arrQuestion.filter(function (el) { return el.id_question >= 0 || el.id >= 0; })
                .length !=
                index + 1}}):_vm._e(),(item.type_question == 1)?_c('Essay',{key:'q-' + index + '-' + item.id_question,attrs:{"item":item,"index":index,"onDelete":function () { return _vm.deleteQuestion(index); },"onDuplicate":function () { return _vm.duplicateQueston(item, index); },"moveUp":function () { return _vm.moveUp(index); },"moveDown":function () { return _vm.moveDown(index); },"canMoveDown":_vm.data.arrQuestion.filter(function (el) { return el.id_question >= 0 || el.id >= 0; })
                .length !=
                index + 1}}):_vm._e(),(item.type_question == 2)?_c('MultipleChoice',{key:'q-' + index + '-' + item.id_question,attrs:{"item":item,"index":index,"onDelete":function () { return _vm.deleteQuestion(index); },"onDuplicate":function () { return _vm.duplicateQueston(item, index); },"moveUp":function () { return _vm.moveUp(index); },"moveDown":function () { return _vm.moveDown(index); },"canMoveDown":_vm.data.arrQuestion.filter(function (el) { return el.id_question >= 0 || el.id >= 0; })
                .length !=
                index + 1}}):_vm._e(),(item.type_question == 3)?_c('Checkbox',{key:'q-' + index + '-' + item.id_question,attrs:{"item":item,"index":index,"onDelete":function () { return _vm.deleteQuestion(index); },"onDuplicate":function () { return _vm.duplicateQueston(item, index); },"moveUp":function () { return _vm.moveUp(index); },"moveDown":function () { return _vm.moveDown(index); },"canMoveDown":_vm.data.arrQuestion.filter(function (el) { return el.id_question >= 0 || el.id >= 0; })
                .length !=
                index + 1}}):_vm._e(),(item.type_question == 5)?_c('ImageQuestion',{key:'q-' + index + '-' + item.id_question,attrs:{"item":item,"index":index,"onDelete":function () { return _vm.deleteQuestion(index); },"onDuplicate":function () { return _vm.duplicateQueston(item, index); },"moveUp":function () { return _vm.moveUp(index); },"moveDown":function () { return _vm.moveDown(index); },"canMoveDown":_vm.data.arrQuestion.filter(function (el) { return el.id_question >= 0 || el.id >= 0; })
                .length !=
                index + 1}}):_vm._e(),([6, 7, 8].includes(item.type_question))?_c('Matrix',{key:'q-' + index + '-' + item.id_question,attrs:{"item":item,"index":index,"onDelete":function () { return _vm.deleteQuestion(index); },"onDuplicate":function () { return _vm.duplicateQueston(item, index); },"moveUp":function () { return _vm.moveUp(index); },"moveDown":function () { return _vm.moveDown(index); },"canMoveDown":_vm.data.arrQuestion.filter(function (el) { return el.id_question >= 0 || el.id >= 0; })
                .length !=
                index + 1}}):_vm._e(),(item.type_question == 9)?_c('File',{key:'q-' + index + '-' + item.id_question,attrs:{"item":item,"index":index,"onDelete":function () { return _vm.deleteQuestion(index); },"onDuplicate":function () { return _vm.duplicateQueston(item, index); },"moveUp":function () { return _vm.moveUp(index); },"moveDown":function () { return _vm.moveDown(index); },"canMoveDown":_vm.data.arrQuestion.filter(function (el) { return el.id_question >= 0 || el.id >= 0; })
                .length !=
                index + 1}}):_vm._e()]})],2),_c('div',{staticClass:"d-flex justify-content-end",staticStyle:{"padding-right":"48px"}},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"btn btn-secondary d-block font-weight-bolder px-8 py-4 mt-5 ml-11",staticStyle:{"border-radius":"10px","font-size":"16px"},on:{"click":_vm.addQuestion}},[_vm._v(" + Tambah Pertanyaan ")])])],1)]):_vm._e(),(true)?_c('v-app',{staticClass:"overflow-hidden",style:({ height: _vm.recordDialog ? 'inherit' : '0px' })},[_c('v-dialog',{attrs:{"width":"500px","persistent":""},model:{value:(_vm.recordDialog),callback:function ($$v) {_vm.recordDialog=$$v},expression:"recordDialog"}},[_c('div',{staticClass:"c-dialog"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"relative pb-10 px-20 pt-20"},[_c('div',{staticClass:"dialog-title text-primary"},[_vm._v(" Izinkan untuk melakukan rekaman? ")]),_c('div',{staticClass:"d-flex justify-content-center mt-6 mb-6"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:("tolak-" + (_vm.data.recording.id_question)),staticClass:"tolak-btn",style:(_vm.data.recording.id_question == null ||
                  _vm.data.recording.id_question < 0
                    ? 'background: #E32910; color: #FFFFFF'
                    : 'background: #FFFFFF; color: #E32910'),on:{"click":function () { return _vm.toggleRecording(false); }}},[_vm._v(" Tolak ")]),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:("izinkan-" + (_vm.data.recording.id_question)),staticClass:"izinkan-btn",style:(_vm.data.recording.id_question == 0 ||
                  _vm.data.recording.id_question > 0
                    ? 'background: #06B56F; color: #FFFFFF'
                    : 'background: #FFFFFF; color: #06B56F'),on:{"click":function () { return _vm.toggleRecording(true); }}},[_vm._v(" Izinkan ")])]),(
                _vm.data.recording.id_question == 0 ||
                  _vm.data.recording.id_question > 0
              )?_c('div',[_c('b-form-group',[_c('div',{staticClass:"input-title"},[_vm._v(" Mulai rekaman pada pertaanyan ke ")]),_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-2 pl-3",attrs:{"type":"number","autocomplete":"off"},model:{value:(_vm.data.recording.start),callback:function ($$v) {_vm.$set(_vm.data.recording, "start", $$v)},expression:"data.recording.start"}})],1),_c('b-form-group',[_c('div',{staticClass:"input-title"},[_vm._v(" Akhiri rekaman pada pertanyaan ke ")]),_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-2 pl-3",attrs:{"type":"number","autocomplete":"off"},model:{value:(_vm.data.recording.end),callback:function ($$v) {_vm.$set(_vm.data.recording, "end", $$v)},expression:"data.recording.end"}})],1)],1):_vm._e(),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"btn btn-primary mx-auto d-block font-weight-bolder px-12 py-2 mb-3 mt-10 font-size-3",staticStyle:{"border-radius":"100px"},on:{"click":_vm.recordingSubmit}},[_vm._v(" Selesai ")])])],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }